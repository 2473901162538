import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Metaverse"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container>
                        {/* <h6 className="mt-5">Designing experiences that Engage, Convert, and deliver Results.</h6> */}
                        <p className="mt-5">TEQT is a digital transformation company that provides a range of solutions to help businesses stay ahead in a rapidly evolving digital landscape. In the context of the metaverse, TEQT offers a suite of capabilities that enable organizations to create, operate, and monetize immersive, interactive, and engaging virtual experiences.</p>
                        <p>Some of the key capabilities offered by TEQT in the metaverse space include:-</p>

                        <ul className="circle-list">
                            <li className="mt-2"> <span className="text-black">Virtual world creation:</span> TEQT provides tools and services to help businesses build and launch their own virtual worlds, customizing them to fit their specific needs and goals.</li>
                            <li className="mt-2"><span className="text-black"> User experience design:</span> TEQT offers a wide range of user experience design services, from prototyping to user testing, to help ensure that virtual experiences are engaging, intuitive, and accessible to all users.</li>
                            <li className="mt-2"><span className="text-black"> Monetization:</span> TEQT provides solutions to help businesses generate revenue from their virtual worlds, including in-world advertising, virtual goods sales, and other monetization models.</li>
                            <li className="mt-2"> <span className="text-black">Analytics and insights:</span> TEQT offer advanced analytics and insights tools to help businesses understand user behavior, track key performance metrics, and optimize their virtual experiences over time.</li>
                        </ul>

                        <h6 className="mt-3">Engineering, Domain Expertise – Key ingredients to successful Metaverse implementations</h6>
                        <p className="mt-3">TEQT leverages its wide expertise in engineering and domain knowledge to build custom metaverse solutions by combining its extensive technical expertise with deep domain knowledge in various industries. This enables TEQT to provide customized and highly relevant virtual experiences for its clients, designed to meet their specific business needs and goals.</p>

                        {/* <h6>Our differentiated approach</h6> */}

                        <ul className="circle-list mt-3">
                            <li className="mt-2"><span className="text-black"> Technical expertise:</span> TEQT has a team of experienced engineers and technologists with a wide range of skills and experience in the latest technologies, including blockchain, AI, and virtual and augmented reality. This allows the company to build custom metaverse solutions that leverage the latest and most advanced technology.</li>
                            <li className="mt-2"><span className="text-black"> Domain knowledge:</span> TEQT has deep domain knowledge in a range of industries, including retail, healthcare, finance, and more. This enables the company to understand the specific challenges and opportunities that businesses face in each industry, and to design custom virtual experiences that address those needs.</li>
                            <li className="mt-2"><span className="text-black"> Design thinking:</span> TEQT uses Design thinking approach to metaverse development, combining technical expertise with user-centered design principles to create virtual experiences that are engaging, accessible, and easy to use.</li>
                            <li className="mt-2"><span className="text-black"> Agile development:</span> TEQT adopts an agile development approach to metaverse development, allowing for rapid prototyping, iteration, and optimization of virtual experiences. This enables the company to deliver high-quality solutions that are flexible and responsive to changing business needs.</li>
                        </ul>
                        <h6 className="mt-3">Extensive Partner Network:</h6>
                        <p className="mt-2 mb-5">TEQT leverages its technology partner network to serve its customers by collaborating with a wide range of leading technology companies to provide the best possible solutions for its clients. By partnering with these companies, TEQT can offer its customers a comprehensive suite of technology solutions that are designed to meet their specific needs and requirements.</p>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query MetaversePageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
